import styled, { css } from 'styled-components'
import { AssistiveTechnologyOnly, Icon, FocusStyles } from '@boxine/tonies-ui'
import 'focus-visible'

export const IconButton = styled.button`
  all: unset;
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;

  ${FocusStyles}
`

export const SearchBarLabel = styled.label`
  ${AssistiveTechnologyOnly}
`

export const StyledIcon = styled(Icon)`
  height: 1.5rem;
  width: 1.5rem;
`

export const InputStyles = css`
  /* Remove unwanted styles such as inner-shadow on mobile etc. */
  appearance: none;

  width: 100%;
  font-size: 1rem;
  font-family: ${props => props.theme.fonts.serif};
  font-weight: 500;

  &.focus-visible {
    outline: none;
  }
`
