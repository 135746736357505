import { hijackEffects } from 'stop-runaway-react-effects'

if (
  process.env.REACT_APP_ENVIRONMENT === 'dev' &&
  // Don't run in e2etests. This is undefined in normal browsers
  // and Puppeteer sets this property to true.
  !navigator.webdriver
) {
  // how many use(Layout)Effect runs do we want to allow in how many milliseconds
  hijackEffects({ callCount: 10, timeLimit: 500 })
}

// https://github.com/kentcdodds/stop-runaway-react-effects
// Catches situations when a react use(Layout)Effect runs repeatedly in rapid succession.
// only runs in development.
