import { Headline2, OmniButton, useSliderColumns } from '@boxine/tonies-ui'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { InnerContainer } from '../../layouts/LayoutV2'
import {
  AdditionalContentWrapper,
  HeaderLayout,
  StyledHorizontalScrollList,
} from '../SharedAudioLibraryStyles'
import { FindMoreCard } from './FindMoreCard'

interface DiscoverMoreSliderProps {
  children: React.ReactNode
  headline?: string
  findMoreHref?: string
  'data-trackingid'?: string
  'data-testid'?: string
  hasNoBackgroundColor?: boolean
  padding?: string
  definedColumns?: {
    columnAmountDefault: number
    columnAmountMobileL: number
    columnAmountTablet: number
    columnAmountLaptop: number
  }
  scrollByOne?: boolean
  sliderType?: 'purchasable' | 'free' | 'owned' | undefined
}

export function DiscoverMoreSlider({
  children,
  findMoreHref,
  headline,
  'data-trackingid': trackingId,
  'data-testid': testId,
  hasNoBackgroundColor = false,
  padding,
  definedColumns = {
    columnAmountDefault: 2.5,
    columnAmountMobileL: 3.5,
    columnAmountTablet: 5.5,
    columnAmountLaptop: 6,
  },
  scrollByOne = true,
  sliderType = undefined,
}: DiscoverMoreSliderProps) {
  const { t } = useTranslation()
  const columns = useSliderColumns(definedColumns)
  const hasHeader = headline && findMoreHref

  const findMoreLinkTestId = `find-more-link${sliderType ? '-' : ''}${
    sliderType || ''
  }`

  return (
    <AdditionalContentWrapper
      data-testid={testId}
      hasNoBackgroundColor={hasNoBackgroundColor}
      padding={padding}
    >
      {hasHeader && (
        <InnerContainer>
          <HeaderLayout>
            {headline && (
              <Headline2
                align="left"
                data-testid={`headline${sliderType ? '-' : ''}${
                  sliderType || ''
                }`}
              >
                {headline}
              </Headline2>
            )}

            {findMoreHref && React.Children.count(children) > columns && (
              <OmniButton
                data-trackingid={trackingId}
                href={findMoreHref}
                data-testid={findMoreLinkTestId}
              >
                {t('default:FindMore')}
              </OmniButton>
            )}
          </HeaderLayout>
        </InnerContainer>
      )}
      <StyledHorizontalScrollList
        scrollByOne={scrollByOne}
        columns={columns}
        alwaysShowNavigation={
          columns === definedColumns.columnAmountLaptop &&
          React.Children.count(children) > definedColumns.columnAmountLaptop
        }
      >
        {children}
        {findMoreHref && (
          <FindMoreCard
            findMoreHref={findMoreHref}
            findMoreLinkTestId={findMoreLinkTestId}
            trackingId={trackingId}
            findMoreText={t('default:FindMore')}
          />
        )}
      </StyledHorizontalScrollList>
    </AdditionalContentWrapper>
  )
}
