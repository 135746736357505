import { toast } from '@boxine/tonies-ui'
import * as Sentry from '@sentry/browser'
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import TagManager from 'react-gtm-module'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'urql'
import { useAuth } from '../auth'
import { useFeatureFlagContext } from '../feature-flags'
import {
  fetchFlags,
  isRegion,
  omitEqualKeyValues,
  patchUser,
} from './profileFunctions'
import { userProfileQuery, UserProfileQuery } from './profileQuery'
import { User, Flags, Region } from './profileTypes'

const initialState: UserProfileContext = {
  region: 'row',
  updateUser: () => Promise.resolve(),
  user: null,
  flags: {
    contentRegion: 'row',
    canBuyTunes: false,
  },
  isFetching: true,
}

interface UserProfileContext {
  user: User | null
  flags: Flags
  region: Region
  updateUser: (newUserData: Partial<User>) => Promise<void>
  isFetching: boolean
}

const Context = createContext<UserProfileContext>(initialState)

export function useUserProfile() {
  const context = useContext(Context)

  if (!context)
    throw new Error(
      'In order to use this hook, please add the <UserProfileProvider> to your app'
    )

  return context
}

interface UserProfileProviderProps {
  children: ReactNode
  onError?: (error: unknown) => void
}

type AuthUserProfileState =
  | {
      state: 'initial'
    }
  | {
      state: 'loading'
    }
  | {
      state: 'error'
      message: string
    }
  | {
      state: 'loaded'
      data: {
        attributes: {
          playtime_recommendations?: string[]
        }
      }
    }

export function UserProfileProvider({
  children,
  onError,
}: UserProfileProviderProps) {
  const { t } = useTranslation()
  const featureFlags = useFeatureFlagContext()
  const { isAuthenticated, isChecked, loadUserProfile } = useAuth()

  const [user, setUser] = useState<User | null>(initialState.user)
  const [flags, setFlags] = useState<Flags>(initialState.flags)
  const [authUserProfile, setAuthUserProfile] = useState<AuthUserProfileState>({
    state: 'initial',
  })
  const [isFetching, setIsFetching] = useState(initialState.isFetching)

  const [result] = useQuery<UserProfileQuery>({
    query: userProfileQuery,
    pause: !isAuthenticated,
    requestPolicy: 'network-only',
  })

  const handleError = useCallback(
    error => {
      if (typeof onError === 'function') {
        onError(error)

        return
      }

      toast(t('default:TOASTSomethingWentWrong'), 'error', {
        toastId: 'change-user-profile-error',
      })
    },
    [onError, t]
  )

  /**
   * In case we are unauthenticated we fetch only flags information
   */
  useEffect(() => {
    if (!isChecked || isAuthenticated) {
      return
    }

    async function run() {
      setIsFetching(true)

      try {
        const flags = await fetchFlags()

        if (flags) {
          setFlags(flags)
        }
      } catch (error) {
        handleError(error)
      } finally {
        setIsFetching(false)
      }
    }

    run()
  }, [handleError, isAuthenticated, isChecked])

  /**
   * Fetch the user profile from keycloak
   */
  useEffect(() => {
    if (
      authUserProfile.state === 'loaded' ||
      authUserProfile.state === 'error'
    ) {
      return
    }

    if (authUserProfile.state === 'initial') {
      setAuthUserProfile({ state: 'loading' })
    }

    async function run() {
      try {
        const data = await loadUserProfile()

        if (data) {
          setAuthUserProfile({ state: 'loaded', data })
        }
      } catch (error) {
        if (error instanceof Error) {
          setAuthUserProfile({ state: 'error', message: error.message })
        } else {
          setAuthUserProfile({ state: 'error', message: 'Unknown error' })
        }

        handleError(error)
      }
    }

    run()
  }, [handleError, loadUserProfile, authUserProfile])

  /**
   * If we have a valid and authenticated user we fetch the user profile
   */
  useEffect(() => {
    if (!result.data || authUserProfile.state !== 'loaded') {
      return
    }

    const { me, flags } = result.data

    setUser({
      ...me,
      region: isRegion(me.region) ? me.region : 'row',
      playtimeRecommendations:
        authUserProfile.data.attributes.playtime_recommendations !==
          undefined &&
        authUserProfile.data.attributes.playtime_recommendations.length > 0 &&
        authUserProfile.data.attributes.playtime_recommendations[0] === 'true',
    })

    setFlags({
      ...flags,
      contentRegion: isRegion(flags.contentRegion)
        ? flags.contentRegion
        : 'row',
    })
    setIsFetching(false)
  }, [authUserProfile, result.data])

  /**
   * Handle query error
   */
  useEffect(() => {
    if (!result.error) {
      return
    }

    setIsFetching(false)
    handleError(result.error)
  }, [handleError, result.error])

  /**
   * Set user in third party tools TagManager, ConfigCat and Sentry
   */
  useEffect(() => {
    if (!user) {
      return
    }

    const { uuid, email, unicodeLocale, country } = user
    const { contentRegion } = flags

    TagManager.dataLayer({
      dataLayer: {
        userId: uuid,
        user_country: country,
        user_region: contentRegion,
      },
    })

    Sentry.configureScope(scope => {
      scope.setUser({
        id: uuid,
      })
    })

    featureFlags.setUser({
      identifier: uuid,
      email: email,
      country: country,
      custom: {
        region: contentRegion,
        locale: unicodeLocale,
      },
    })
  }, [featureFlags, flags, user])

  /**
   * Updates the user and syncs it with the backend
   * @param nextUser {Partial<User>} new user data to be updated
   * @returns {Promise<void>}
   */
  const updateUser = async (nextUser: Partial<User>): Promise<void> => {
    if (isFetching || !user || !nextUser) {
      return
    }

    // Do not update the user if there is no data or the data is equal
    if (Object.keys(omitEqualKeyValues(user, nextUser)).length === 0) {
      return
    }

    try {
      await patchUser(nextUser)

      setUser({
        ...user,
        ...nextUser,
      })
    } catch (error) {
      handleError(error)
    }
  }

  if (isFetching) {
    // TODO: Add a loading state
    return null
  }

  return (
    <Context.Provider
      value={{
        user,
        flags,
        region: flags.contentRegion,
        updateUser,
        isFetching,
      }}
    >
      {children}
    </Context.Provider>
  )
}
