import React from 'react'
import styled from 'styled-components'
import * as icons from '@boxine/tonies-ui/icons'
import { StyledLink, Square, SquareInner } from '../TuneCard'
import { StyledIcon } from '../SearchBar/SharedStyles'

const SquareInnerWithIcon = styled(SquareInner)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.middlegrey};
`
const circleSize = 3
const styledIconSize = 1.5
const IconCircle = styled.div`
  background-color: ${props => props.theme.BrandPrimary};
  border-radius: 50%;
  width: ${circleSize}rem;
  height: ${circleSize}rem;
  padding: ${(circleSize - styledIconSize) / 2}rem;
  color: ${props => props.theme.colors.white};
`

export function FindMoreCard({
  findMoreHref,
  findMoreLinkTestId,
  trackingId,
  findMoreText,
}) {
  return (
    <StyledLink
      to={findMoreHref}
      data-testid={findMoreLinkTestId}
      data-trackingid={trackingId}
    >
      <Square>
        <SquareInnerWithIcon>
          <IconCircle>
            <StyledIcon title={findMoreText} type={icons.search} />
          </IconCircle>
        </SquareInnerWithIcon>
      </Square>
    </StyledLink>
  )
}
