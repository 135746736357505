import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import bundledMetadata from '../../assets/meta.json'

// this is a hidden feature for product people, customer care, etc.
// to determine which version of the SPA they are currently using
// by clicking multiple times on the copyright info
const CopyrightVersionInfo = () => {
  const { t } = useTranslation()
  const [clickCount, setClickCount] = useState(0)

  function handleClick() {
    setClickCount(clickCount + 1)
  }

  function shouldShowVersion() {
    return clickCount > 0 && clickCount % 3 === 0
  }

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <span onClick={handleClick}>
      {shouldShowVersion()
        ? `${bundledMetadata.commit} ${bundledMetadata.dateTime}`
        : t('default:FooterCredits', {
            year: new Date().getFullYear(),
          })}
    </span>
  )
}

export default CopyrightVersionInfo
