import React, { useEffect, useRef, useState } from 'react'
import { TuneCardItem } from '../TuneCard/types'
import { TuneCard } from '../TuneCard'
import { useShop } from '../../hooks/useShop'
import { DiscoverMoreSlider } from './DiscoverMoreSlider'

// this component is a wrapper around DiscoverMoreSlider
// it fetches Tunes from the Shop API and passes rendered TuneCoards to DiscoverMoreSlider
// the props should be the same as for DiscoverMoreSlider to make them interchangeable
interface DiscoverMoreTunesSliderProps {
  // props shared with DiscoverMoreSlider
  headline?: string
  findMoreHref?: string
  'data-trackingid'?: string
  'data-testid'?: string
  hasNoBackgroundColor?: boolean
  padding?: string
  definedColumns?: {
    columnAmountDefault: number
    columnAmountMobileL: number
    columnAmountTablet: number
    columnAmountLaptop: number
  }

  // additional props
  seriesSlug?: string // the slug of the series to fetch Tunes from
  tunesCardsOnly?: boolean // only render TuneCards, not a slider
  limit?: number // number of Tunes to fetch
}

export function DiscoverMoreTunesSlider({
  'data-trackingid': trackingId,
  'data-testid': testId,
  headline,
  findMoreHref,
  hasNoBackgroundColor,
  padding,
  seriesSlug,
  tunesCardsOnly = false, // set to true where Tunes are not displayed as a slider
  limit = 10,
}: DiscoverMoreTunesSliderProps) {
  const { getTunes } = useShop()
  const [tunes, setTunes] = useState<TuneCardItem[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const abortControllerRef = useRef(new AbortController())

  useEffect(() => {
    const abortController = abortControllerRef.current
    const fetchTunes = async () => {
      setIsLoading(true)
      let fetchedTunes
      try {
        fetchedTunes = await getTunes({
          seriesSlug,
          limit,
          abortController,
        })
      } catch (error: any) {
        if (error['message'] === 'canceled') {
          // do nothing, because this is expected to happen
        } else {
          throw error // re-throw
        }
      }
      setTunes(fetchedTunes)
      setIsLoading(false)
    }

    fetchTunes()

    // cleanup function
    return () => {
      abortController.abort() // abort the fetch request
    }
  }, [seriesSlug, limit, getTunes])

  function renderTunesCards() {
    if (isLoading) {
      return (
        <TuneCard
          tune={{ id: 'loading', title: 'Loading ...' }}
          data-testid="tunes-loading"
        />
      )
    } else {
      return tunes.map(tune => (
        <TuneCard
          key={tune.id}
          tune={tune}
          data-trackingid={`${trackingId}__cover`}
          data-tracking-variant={tune && `${tune.series?.name}-${tune.title}`}
          data-tracking-variant-type="content-paid"
        />
      ))
    }
  }

  if (tunesCardsOnly) {
    return <>{renderTunesCards()}</>
  } else {
    return (
      <DiscoverMoreSlider
        data-testid={testId}
        data-trackingid={trackingId}
        headline={headline}
        findMoreHref={findMoreHref}
        hasNoBackgroundColor={hasNoBackgroundColor}
        padding={padding}
        sliderType="purchasable"
      >
        {renderTunesCards()}
      </DiscoverMoreSlider>
    )
  }
}
