import { gql } from 'graphql-tag'
import { Household } from '../../utils/tonie-utils'
export const contentToniePageQuery = gql`
  query ContentTonies {
    households {
      id
      name
      access
      contentTonies {
        id
        title
        lock
        series {
          id
          name
          group {
            id
            name
          }
        }
        imageUrl
        tune {
          id
          item {
            title
          }
        }
      }
    }
  }
`

export interface ContentToniePageQuery {
  households: Household[]
}
