import { useCallback } from 'react'
import axios from 'axios'
import { useUserProfile } from '../providers/user-profile'
import mainCategorySlugs from '../shop-main-category-slugs.json'
import { Region } from '../providers/user-profile/profileTypes'
import { useUtm } from '../providers/utm/UtmProvider'
import { TuneCardItem } from '../components/TuneCard/types'

const regionToLcCC = {
  dach: 'de-de',
  uki: 'en-gb',
  usa: 'en-us',
  fra: 'fr-fr',
  eur: 'en-eu',
} as const

const shopUrlByEnv = {
  local: 'https://local.tonie.cloud',
  dev: 'https://dev.tonie.cloud',
  stage: 'https://stage.tonie.cloud',
  prod: 'https://tonies.com',
}

export const SHOP_API_PRODUCTS_ENDPOINT = `${process.env.REACT_APP_SHOP_API_URL}/os/products/`

const USA_SHOP_URL = 'https://us.tonies.com'

// does not support US
function getShopDomain() {
  const env = process.env.REACT_APP_ENVIRONMENT || 'prod'
  return shopUrlByEnv[env]
}

/**
 * Retrieves the shop URL based on the provided locale country code (lcCC) and the current environment.
 *
 * @param {string} lcCC - The locale country code to append to the shop URL.
 * @returns {string} The complete shop URL for the given locale country code.
 */
function getShopUrlByLcCC(lcCC: string): string {
  if (lcCC === 'en-us') {
    return USA_SHOP_URL
  }

  const link = getShopDomain()

  return `${link}/${lcCC}`
}

/**
 * Retrieves the locale country code (lcCC)) based on the provided region.
 *
 * @param {Region} region - The region for which to get the lcCC.
 * @returns {string} The lcCC corresponding to the given region.
 */
function getLcCCbyRegion(region: Region): string {
  return regionToLcCC[region]
}

/**
 * Retrieves the main shop category based on the provided locale country code (lcCC).
 *
 * @param lcCC - The locale country code to search for.
 * @returns The main shop category that matches the provided lcCC, or undefined if no match is found.
 */
function getMainShopCategoryByLcCC(lcCC: string) {
  return mainCategorySlugs.find(item => item.lcCC.toLowerCase() === lcCC)
    ?.categories
}

/**
 * Custom hook to generate shop-related URLs and retrieve shop categories.
 *
 * @returns An object containing methods to generate shop URLs and retrieve main shop categories by slug.
 */
export function useShop() {
  const { region } = useUserProfile()
  const { getUtmParams } = useUtm()
  const utmSearchParams = getUtmParams()

  const lcCC = getLcCCbyRegion(region)
  const mainCategories = getMainShopCategoryByLcCC(lcCC)

  /**
   * Constructs a full URL for the shop by appending the given path to the base shop link.
   *
   * @param path - The path to append to the base shop link.
   * @returns A new URL object representing the full shop URL.
   */
  function getShopUrl(path?: string) {
    const shopLink = getShopUrlByLcCC(lcCC)

    if (!path || path.length === 0) {
      return shopLink
    }

    return `${shopLink}/${path}`
  }

  /**
   * Appends search parameters to the given URL based on the current window location search parameters.
   * If the 'webview' parameter is present in the search parameters with the value 'app', it adds 'webview=app' to the URL.
   * It also adds any utm parameters to the URL.
   *
   * @param url - The URL to append the search parameters to.
   * @returns The updated URL with the appended search parameters.
   */
  function appendParams(url: string) {
    const searchParams = new URLSearchParams(window.location.search)
    const newUrl = new URL(url)

    // Add webview=app to shopParams if it is present in searchParams
    if (searchParams.get('webview') === 'app') {
      newUrl.searchParams.set('webview', 'app')
    }

    // Add utm params to shopParams if it is present in searchParams
    utmSearchParams.forEach((value, key) => {
      newUrl.searchParams.set(key, value || '')
    })

    return newUrl.toString()
  }

  /**
   * Creates a link by sales ID.
   *
   * @param salesId - The sales ID to create the link for.
   * @returns The created link if the sales ID is provided and the redirect to the audio library PDP to shop feature is enabled, otherwise undefined.
   */
  function createLinkBySalesId(salesId: string | undefined) {
    const url = getShopUrl()

    if (!salesId || url === USA_SHOP_URL) {
      return url
    }

    return appendParams(`${url}/r/${salesId}`)
  }

  /**
   * Creates a link based on the provided tune.
   * If the tune is undefined, has no salesId, or is marked as myTune, returns undefined.
   * Otherwise, returns a link created using the tune's salesId.
   *
   * @param tune - The tune object to create a link for.
   * @returns The created link or undefined.
   */
  function createLinkByTune(tune: TuneCardItem | undefined) {
    if (!tune || !tune.salesId || tune.myTune) {
      return undefined
    }

    // direct link into the shop should be preferred, to avoid redirects
    if (tune.shopProductUrl && tune.shopProductUrl?.length > 0) {
      const shopDomain = getShopDomain()
      return appendParams(`${shopDomain}${tune.shopProductUrl}`)
    }

    return createLinkBySalesId(tune.salesId)
  }

  /**
   * Retrieves the main shop category by its slug.
   *
   * @param slug - The slug of the category to find.
   * @returns The category object if found, otherwise undefined.
   */
  function getMainShopCategoryBySlug(slug: string) {
    if (!mainCategories) {
      return
    }

    return mainCategories.find(
      (entry: { key: string; slug: string }) => entry.key === slug
    )?.slug
  }

  function createLinkByMainCategory(slug: string) {
    const categorySlug = getMainShopCategoryBySlug(slug)

    return getShopUrl(categorySlug)
  }

  /**
   * Generates a URL for the tunes overview page with optional category slugs as query parameters.
   *
   * @param categorySlugs - A single category slug or an array of category slugs to be appended as query parameters.
   * @returns The complete URL as a string with the provided category slugs.
   */
  function createLinkForTunesOverview(categorySlugs?: string[] | string) {
    const slug = getMainShopCategoryBySlug('tunes')
    const shopLink = getShopUrl(slug)

    if (shopLink.startsWith(USA_SHOP_URL)) {
      return shopLink
    }

    const url = new URL(shopLink)

    const slugs = Array.isArray(categorySlugs) ? categorySlugs : [categorySlugs]

    if (slugs.length > 0) {
      slugs.forEach(slug => {
        if (!slug) {
          return
        }

        url.searchParams.append('categorySlug', slug)
      })
    }

    return url.toString()
  }

  const getTunes = useCallback(
    async ({
      seriesSlug,
      limit = 10,
      abortController,
    }: {
      seriesSlug?: string
      limit?: number
      abortController: AbortController
    }): Promise<TuneCardItem[]> => {
      const params = new URLSearchParams({
        categoryKey: 'tunes',
        shopLocale: lcCC,
        sort: '-available,-soldLastMonth,series.name.keyword,name.keyword',
        withBucketAggs: '0',
        limit: limit.toString(),
      })
      if (seriesSlug) {
        params.append('categorySlug', seriesSlug)
      }

      const response = await axios.get(
        `${SHOP_API_PRODUCTS_ENDPOINT}?${params}`,
        { signal: abortController.signal }
      )

      if (response.data.documents) {
        return response.data.documents.map(shopTune => {
          return {
            id: shopTune.productKey, // the Shop API productKey is set to the Cloudservices Tune id
            title: shopTune.name,
            salesId: shopTune.salesId,
            thumbnail: shopTune.images[0].url,
            shopProductUrl: shopTune.productUrl,
          }
        })
      } else {
        return []
      }
    },
    [lcCC]
  )

  return {
    createLinkBySalesId,
    createLinkByTune,
    createLinkByMainCategory,
    createLinkForTunesOverview,
    shopLink: getShopUrl(),
    getTunes,
  }
}
